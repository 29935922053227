import React, { useEffect } from 'react';
import { Box, Divider, useMediaQuery, Grid, Button } from '@mui/material';
import Header from '../components/header';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useLanguage from '../useLanguage';

const ReparuPage = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { language } = useLanguage();

    const formatText = (text: string) => {
        return text.split('\n').map((str, index) => (
            <React.Fragment key={index}>
                {str}
                <br />
            </React.Fragment>
        ));
    };

    useEffect(() => {
        i18n.changeLanguage(language);
    }, []);

    return (
        <div
            style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                overflowX: 'hidden',
                overflowY: 'auto',
            }}
        >
            <Header textColor="#000000" backgroundColor="white" />
            <Box sx={{ flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                mt: 12,
                ml: isMobile ? 3 : 8,
                pt: '64px'
            }}>
                <h1 style={{ fontSize: isMobile ? '2rem' : '4rem', marginBottom: '0.5rem' }}>Reparu</h1>
                {i18n.language === 'ja' && (
                    <h3 style={{ marginTop: '0' }}>リパル</h3>
                )}
                {isMobile && 
                    <Divider sx={{ mt: 3, mb: 3, mr: 2 }} />
                }
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item md={5}>
                        <Box sx={{ mt: isMobile ? 0 : 3, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                href="https://customer.reparu.jp"
                                target="_blank" 
                                rel="noopener noreferrer"
                                sx={{
                                    fontSize: !isMobile ? '1rem' : '0.8rem',
                                    minWidth: '100px',
                                    padding: !isMobile ? '28px 60px' : '',
                                    backgroundColor: '#45B6AF', 
                                    '&:hover': {
                                        backgroundColor: '#45B6AF',
                                    },
                                    '&:focus': {
                                        backgroundColor: '#45B6AF',
                                    }
                                }}
                            >
                                {t('User Link Label')}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                href="https://shop.reparu.jp"
                                target="_blank" 
                                rel="noopener noreferrer"
                                sx={{
                                    fontSize: !isMobile ? '1rem' : '0.8rem',
                                    minWidth: '165px',
                                    padding: !isMobile ? '28px 60px' : '',
                                    '&:hover': {
                                        backgroundColor: '#1976d2',
                                    },
                                    '&:focus': {
                                        backgroundColor: '#1976d2',
                                    }
                                }}
                            >
                                {t('Shop Link Label')}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item md={6}>
                        <Box sx={{ mt: isMobile ? 0 : 3, position: 'relative' }}>
                            <span style={{ fontSize: '1.2rem' }}>
                                {formatText(t('Reparu Description'))}
                            </span>
                        </Box>
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 10, mb: 3, mr: isMobile ? 2 : 10 }} />
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item md={11}>
                        <Box sx={{ mt: isMobile ? 0 : 3, position: 'relative' }}>
                            <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                {t('Reparu Header')}
                            </span>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ display: isMobile ? '' : 'flex', alignItems: 'center' }}>
                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item md={3}>
                            <Box sx={{ mt: 10 }}>
                                <span style={{ fontSize: '1.5rem' }}>
                                    {t('Reparu Features')}
                                </span>
                            </Box>
                        </Grid>
                        <Grid item md={8}>
                            {[...Array(5)].map((_, index) => (
                                <Box key={index} sx={{ mt: 5 }}>
                                    <Divider sx={{ mt: 3, mb: 3 }} />
                                    <Grid container spacing={4} alignItems="center">
                                        <Grid item md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box sx={{ width: 100, height: 100, borderRadius: '50%', border: '2px solid #45B6AF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>{`0${index + 1}`}</span>
                                            </Box>
                                        </Grid>
                                        <Grid item md={10}>
                                            <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                                {t(`Reparu Feature${index + 1}.title`)}
                                            </span>
                                            <p style={{ marginTop: '0.5rem', fontSize: '1rem' }}>
                                                {t(`Reparu Feature${index + 1}.description`)}
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ mt: 10, mb: 3, mr: isMobile ? 2 : 10 }} />
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item md={11}>
                        <Box sx={{ mt: isMobile ? 0 : 3, position: 'relative' }}>
                            <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                {t('Reparu Background Header')}
                            </span>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ display: isMobile ? '' : 'flex', alignItems: 'center' }}>
                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item md={3}>
                            <Box sx={{ mt: 10 }}>
                                <span style={{ fontSize: '1.5rem' }}>
                                    {t('Reparu Background Features')}
                                </span>
                            </Box>
                        </Grid>
                        <Grid item md={8}>
                            {[...Array(3)].map((_, index) => (
                                <Box key={index} sx={{ mt: 5 }}>
                                    <Divider sx={{ mt: 3, mb: 3 }} />
                                    <Grid container spacing={4} alignItems="center">
                                        <Grid item md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box sx={{ width: 100, height: 100, borderRadius: '50%', border: '2px solid #45B6AF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>{`0${index + 1}`}</span>
                                            </Box>
                                        </Grid>
                                        <Grid item md={10}>
                                            <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                                {t(`Reparu Background Feature${index + 1}.title`)}
                                            </span>
                                            <p style={{ marginTop: '0.5rem', fontSize: '1rem' }}>
                                                {t(`Reparu Background Feature${index + 1}.description`)}
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={2} alignItems="flex-start" sx={{ mt: 3 }}>
                    <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ mt: isMobile ? 0 : 3, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                href="https://customer.reparu.jp"
                                target="_blank" 
                                rel="noopener noreferrer"
                                sx={{
                                    fontSize: !isMobile ? '1rem' : '0.8rem',
                                    minWidth: '100px',
                                    padding: !isMobile ? '28px 60px' : '',
                                    backgroundColor: '#45B6AF',  
                                    '&:hover': {
                                        backgroundColor: '#45B6AF',
                                    },
                                    '&:focus': {
                                        backgroundColor: '#45B6AF',
                                    }
                                }}
                            >
                                {t('User Link Label')}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                href="https://shop.reparu.jp"
                                target="_blank" 
                                rel="noopener noreferrer"
                                sx={{
                                    fontSize: !isMobile ? '1rem' : '0.8rem',
                                    minWidth: '165px',
                                    padding: !isMobile ? '28px 60px' : '',
                                    '&:hover': {
                                        backgroundColor: '#1976d2',
                                    },
                                    '&:focus': {
                                        backgroundColor: '#1976d2',
                                    }
                                }}
                            >
                                {t('Shop Link Label')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}/>
            </Box>
        </div>
    );
}

export default ReparuPage;
