import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { I18nextProvider } from 'react-i18next';
import './styles/globals.css';
import HomePage from './components/home';
import CompanyInfoPage from './components/company';
import ServicePage from './components/service';
import CareersPage from './components/careers';
import NewsPage from './components/news';
import ContactPage from './components/contact';
import PolicyPage from './components/policy';
import SystemDevelopmentPage from './components/system-development';
import AppDevelopmentPage from './components/app-development';
import MaintenancePage from './components/maintenance';
import OrderMatePage from './components/order-mate';
import ReparuPage from './components/reparu';
import { store, persistor } from './store';
import i18n from './i18n';


function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <Router>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/company" element={<CompanyInfoPage />} />
              <Route path="/service" element={<ServicePage />} />
              <Route path="/careers" element={<CareersPage />} />
              <Route path="/news" element={<NewsPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/policy" element={<PolicyPage />} />
              <Route path="/system-development" element={<SystemDevelopmentPage />} />
              <Route path="/app-development" element={<AppDevelopmentPage />} />
              <Route path="/maintenance" element={<MaintenancePage />} />
              <Route path="/order-mate" element={<OrderMatePage />} />
              <Route path="/reparu" element={<ReparuPage/>}/>
            </Routes>
          </Router>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
